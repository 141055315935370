import React from "react";
import Footer from "../../components/Footer";
import "./AboutUs.css";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        marginTop: "170px",
      }}
    >
      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Privacy Policy for “Karibu” (as part of “Trusted Times Limited” sales
        division)
      </h2>
      <div className="aboutus-content">
        <p style={{ width: "100%", margin: "0 auto", paddingBottom: "15px" }}>
          Trusted Times Limited” sales division and “Karibu” service provider
          respects the privacy of “Karibu” members. By how we collect, use and
          disclose information that pertains to the privacy of “Karibu” members.
          When you register as a “Karibu” service provider or customer you
          signify your agreement to the Privacy Policy as well as these Terms
          and conditions of use. One of our main priorities is the privacy of
          our visitors through this mobile app. This Privacy Policy document
          contains types of information that is collected, recorded and how we
          use it. This Privacy Policy applies only to our online activities and
          is valid for visitors through this mobile app with regards to the
          information that they shared and/or collected. This policy is not
          applicable to any information collected offline or via channels other
          than this mobile app. Our Privacy Policy was created with the help of
          the Privacy Policy Generator and overrides/supersedes any Privacy
          Policy published/generated elsewhere. Consent By using “Karibu” mobile
          applications, you hereby consent to our Privacy Policy and agree to
          its terms
          <br />
          <br />
          <h4>Information we collect</h4>
          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information. If you
            contact us directly, we may receive additional information about you
            such as your name, email address, phone number, the contents of the
            message and/or attachments you may send us, and any other
            information you may choose to provide. When you register for an
            Account, we may ask for your contact information, including items
            such as name, company name, address, email address, TIN number and
            telephone number
          </p>
          <br />
          <br />
          <h4>How we use your information</h4>
          <p>
            We use the information we collect in various ways, including to:
            Provide, operate, and maintain our mobile app Improve, personalize,
            and expand our mobile application Understand and analyze how you use
            our mobile app Develop new products, services, features, and
            functionality Communicate with you, either directly or through one
            of our partners, including for customer service, to provide you with
            updates and other information relating to the mobile app and for
            marketing and promotional purposes; Send you emails Find and prevent
            fraud
          </p>
          <br />
          <br />
          <h4>Log Files</h4>
          <p>
            A standard procedure of using log files. These files log visitors
            when they visit mobile apps. All hosting companies do this as part
            of hosting services' analytics. The information collected by log
            files include internet protocol (IP) addresses, browser type,
            Internet Service Provider (ISP), date and time stamp, referring/exit
            pages, and possibly the number of clicks. These are not linked to
            any information that is personally identifiable. The purpose of the
            information is for analyzing trends, administering the mobile app,
            tracking users movement on the mobile app and gathering demographic
            information.
          </p>
          <br />
          <br />
          <h4>Advertising Partners Privacy Policies</h4>
          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners/service providers of “Karibu” (as part of
            “Trusted Times Limited” sales division). Third-party ad servers or
            ad networks use technologies like cookies, JavaScript, or Web
            Beacons that are used in their respective advertisements and links
            that appear on our mobile app, which are sent directly to users'
            browsers. They automatically receive your IP address when this
            occurs. These technologies are used to measure the effectiveness of
            their advertising campaigns and/or to personalize the advertising
            content that you see on websites that you visit. Note that “Karibu”
            (as part of “Trusted Times Limited” sales division) has no access to
            or control over these cookies that are used by third-party
            advertisers.
          </p>
          <br />
          <br />
          <h4>Third Party Privacy Policies</h4>
          <p>
            “Karibu” (as part of “Trusted Times Limited” sales division) Privacy
            Policy does not apply to other advertisers or its websites/mobile
            apps. Thus, we are advising you to consult the respective Privacy
            Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options. You can choose to disable cookies
            through your individual browser options. To know more detailed
            information about cookie management with specific web browsers, it
            can be found at the browsers respective websites. You may request
            that a business delete any personal data about the consumer that a
            business has collected. Request that a business/service provider
            that sells a consumer's personal data, not sell the consumer's
            personal data. If you would like assistance from “Trusted Times
            Limited” to exercise any of these rights, we have one month to
            respond to you. Please contact us via email; karibu@vgoapps.com
          </p>
          <br />
          <br />
          <h4>GDPR Data Protection Rights</h4>
          <p>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following: The
            right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service. The
            right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete. The right to erasure – You have the right to request
            that we erase your personal data, under certain conditions. The
            right to restrict processing – You have the right to request that we
            restrict the processing of your personal data, under certain
            conditions. The right to object to processing – You have the right
            to object to our processing of your personal data, under certain
            conditions. The right to data portability – You have the right to
            request that we transfer the data that we have collected to another
            organization, or directly to you, under certain conditions. If you
            make a request, we have one month to respond to you. If you would
            like to exercise any of these rights, please contact us via email;
            karibu@vgoapps.com
          </p>
        </p>
      </div>

      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Terms and Conditions for “Karibu” Customers (as part of “Trusted Times
        Limited” sales division)
      </h2>
      <div className="aboutus-content">
        <p style={{ width: "100%", margin: "0 auto", paddingBottom: "15px" }}>
          I am above 18 years of age/I have obtained consent from my
          parent/guardian upon time of registration. “KARIBU” (as part of
          “Trusted Times Limited” sales division) is only available on “KARIBU”
          mobile apps. I shall provide my original ID upon request of “KARIBU”
          registered service provider. All prices/rates by registered “KARIBU”
          service provider are subject to change without any prior approval or
          notice. “KARIBU” (as part of “Trusted Times Limited” sales division)
          reserves the right to change, discontinue or amend its services, Terms
          of Use, Privacy Policy, Terms & Conditions of KARIBU “For Customer” at
          any given time without any prior approval of the customer or notice.
          All payments of services must be paid directly to registered “KARIBU”
          service providers only. “KARIBU” (as part of “Trusted Times Limited”
          sales division) is not responsible/liable for any booking, service,
          experience, order, communication or financial transactions between
          registered “KARIBU” service provider and a “KARIBU” customer. A
          customer must be connected to WIFI/Internet data to be able to access
          “KARIBU” mobile apps. It is the responsibility of the customer to
          update any change in registered email address, phone number, login
          details or any other personal information required by “KARIBU” (as
          part of “Trusted Times Limited” sales division).
          <br />
          <br />
          <p>
            . “KARIBU” (as part of “Trusted Times Limited” sales division) is
            entitled to closure of the division at any given time without any
            prior notice nor approval. Customer must avail any discount/offer
            within the date of expiry as mentioned on the discount/exclusive
            offer by “KARIBU” service provider. Customers do not hold “KARIBU”
            (as part of “Trusted Times Limited” sales division) responsible nor
            legally liable for any dispute or experience with a registered
            “KARIBU” service provider. Incase of closure of a registered
            “KARIBU” service provider, all services/discounts/offers shall be
            treated as null and void and no legal action shall be taken against
            “KARIBU” (as part of “Trusted Times Limited” sales division) or
            registered “KARIBU” service provider by the customer.
          </p>
          <br />
          <br />
          <p>
            In case of suspected fraud, error and inconsistencies, shall be
            reported by “KARIBU” customers via email to the following address;
            karibu@vgoapps.com Terms & Conditions of KARIBU “For Customer”,
            Terms of Use and Privacy Policy do not coincide with “KARIBU”
            service provider Terms & Conditions, Terms of Use and Privacy
            Policy. Terms & Conditions of KARIBU “For Customer”, “KARIBU” Terms
            of Use and “KARIBU” Privacy Policy (as part of “Trusted Times
            Limited” sales division) which is available on “KARIBU” mobile
            applications only, overrides/ supersedes, anything/ everything and
            available/published elsewhere. When you register/subscribe to
            “KARIBU”, you therefore confirm the following; I HEREBY, declare
            that all information provided by me is true and accurate, I have
            read, understood, agreed and accepted all of the above Terms &
            Conditions of KARIBU “For Customer” (as part of “Trusted Times
            Limited” sales division), “KARIBU” Terms of Use & “KARIBU” Privacy
            Policy before registering or subscribing to “KARIBU”
          </p>
          <br />
          <br />
        </p>
      </div>

      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Terms and Conditions for “Karibu” Service Provider (as part of “Trusted
        Times Limited” sales division)
      </h2>
      <div className="aboutus-content">
        <p style={{ width: "100%", margin: "0 auto", paddingBottom: "15px" }}>
          . Registration to “KARIBU” as a service provider and submission of
          “KARIBU Service Provider Agreement” must be made via “KARIBU” mobile
          applications only. Incase registered “KARIBU” service provider would
          like to withdraw from “KARIBU Service Provider Agreement”, a month
          notice period requesting withdrawal of “KARIBU Service Provider
          Agreement” must be sent via the following email address;
          karibu@vgoapps.com It is the responsibility of the service provider to
          update any change in registered email address, phone number, login
          details, TIN number or any other personal information required by
          “KARIBU” (as part of “Trusted Times Limited” sales division) during
          registration. “KARIBU” (as part of “Trusted Times Limited” sales
          division) reserves the right to change, discontinue or amend its
          services, Terms of Use, Privacy Policy, Terms & Conditions “For
          Service Provider” without any prior approval from the registered
          service provider or prior notice at any given time and an updated
          notification alert shall be sent via “KARIBU” mobile apps informing
          them of the same. “KARIBU” service provider must honor “KARIBU”
          customers with the agreed discount/offer as submitted on “KARIBU”
          mobile applications. “KARIBU” service provider can not amend
          discounts/offers after submission until the expiry date of a
          discount/offer. Service Provider do not hold any shares, financial
          profits, gain or loss against “KARIBU” (as part of “Trusted Times
          Limited” sales division) sales and business revenue. All
          registrations, submissions, discounts/offers as a service provider
          with “KARIBU” (as part of “Trusted Times Limited” sales division) are
          subject to approval.
          <br />
          <br />
          <p>
            “KARIBU” (as part of “Trusted Times Limited” sales division) has the
            right to deny/reject any registration or service by a service
            provider without any reason or explanation. Service provider do not
            hold “KARIBU” (as part of “Trusted Times Limited” sales division)
            responsible or legally liable for any dispute or experience with the
            customer. Registered “KARIBU” service provider grants consent and
            permission for all platforms used for promotion, advertising and
            marketing done by “KARIBU” (as part of “Trusted Times Limited” sales
            division) for the service provider. Registered service provider is
            responsible for informing a customer of any specific terms &
            conditions. Incase of closure/withdrawal of a registered service
            provider, all services/discounts/offers shall be treated as null and
            void and no legal action shall be taken by “KARIBU” (as part of
            “Trusted Times Limited” sales division) against the service
            provider. Registered service provider must be connected to
            WIFI/Internet data to be able to access “KARIBU” mobile apps. All
            payment transactions for services must be received by registered
            “KARIBU” service providers only. “KARIBU” (as part of “Trusted Times
            Limited” sales division) is not responsible/liable for any booking,
            service, experience, order, communication or financial transactions
            between registered “KARIBU” service provider and a “KARIBU” customer
          </p>
          <br />
          <br />
          <p>
            In case of all suspected fraud, error and inconsistencies shall be
            reported by service provider via email to the following address;
            karibu@vgoapps.com Terms & Conditions of KARIBU “For Customer”,
            Terms & Conditions of KARIBU “For Service Provider”, Terms of Use
            and Privacy Policy do not coincide with a registered “KARIBU”
            service providers Terms & Conditions, Terms of Use and Privacy
            Policy. Terms & Conditions of KARIBU “For Customer”, Terms &
            Conditions of KARIBU “For Service Provider”, “KARIBU” Terms of Use
            and “KARIBU” Privacy Policy (as part of “Trusted Times Limited”
            sales division) which is available on “KARIBU” mobile applications
            only, overrides/supersedes, anything/everything and
            available/published elsewhere. When you submit/advertise a service
            as a “KARIBU” service provider on “KARIBU” mobile applications, you
            therefore confirm the following; I HEREBY, declare that all
            information provided by me is true and accurate, I have read,
            understood, agreed and accepted all “KARIBU” (as part of “Trusted
            Times Limited” sales division) Terms of Use, Privacy Policy, Terms &
            Conditions of KARIBU “For Customer" and all of the above "Terms &
            Conditions of KARIBU “For Service Provider".
          </p>
          <br />
          <br />
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
