import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/client";
import { Button, MenuItem, Select, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import swal from "sweetalert";

const AllServices = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState();
  const [vendors, setVendors] = useState();
  const [vendorId, setVendorId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    fetchCategories();
    fetchVendors();
  }, [currentPage, del]);

  const fetchProducts = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/service", {
      pageNumber: currentPage,
    });
    setGetList(data?.products);
console.log(data)
    setPageSize(data?.pageCount);
    setIsLoaded(false);
  };

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/category/all");
    setCat(data);
  };

  const fetchVendors = async () => {
    const { data } = await apiClient.get("/vendor");
    setVendors(data);
  };

  const handlDeleteById = async (id) => {
    const result = await apiClient.delete("/service/delete", { id });
    if (result.ok) {
      swal("Delete Success");
      setDel(!del);
    }
  };

  const handleSubmit = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/service", {
      pageNumber: currentPage,
      category: catId,
      vendor: vendorId,
    });
    setPageSize(data.pageCount);
    setGetList(data.products);
    setIsLoaded(false);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Services</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Services</li>
          </ol>
          <div className="row">
            <div className="col-lg-3 col-md-2">
              <div className="form-group">
                <label className="form-label">Service Vendor</label>
                <Select
                  className="form-control"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={vendorId}
                  label="Vendor"
                  onChange={(e) => setVendorId(e.target.value)}
                >
                  {vendors?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </div>
            </div>
            <div className="col-lg-2 col-md-2">
              <div className="form-group">
                <label className="form-label">Service Category*</label>

                <Select
                  className="form-control"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={catId}
                  label="Category"
                  onChange={(e) => setCatId(e.target.value)}
                >
                  {cat?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </div>
            </div>
            <button
              className="save-btn hover-btn"
              type="submit"
              onClick={handleSubmit}
            >
              Filter
            </button>
            <button
              className="save-btn hover-btn"
              type="submit"
              onClick={() => {
                setVendorId();
                setCatId();

                window.location.reload();
              }}
            >
              Clear Filter
            </button>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Services</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: 60 }}>Id</th>
                        <th style={{ width: 100 }}>Image</th>
                        <th>Name</th>
                        <th>Category</th>
                  
                        <th>Vendor</th>
                        <th>Price</th>
                        <th>Action</th>
                     
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && getList?.length > 0
                        ? getList?.map((row, index) => {
                            return (
                              <tr key={index}>
                                <td>{row?._id}</td>
                                <td>
                                  <div className="cate-img-5">
                                    <img
                                      src={row?.image ? row?.image[0] : ""}
                                      alt="service-name"
                                    />
                                  </div>
                                </td>
                                <td>{row?.name}</td>

                                <td>{row?.category?.name}</td>

                                <td>{row?.vendor?.name}</td>

                                <td>{row.sell_price} $</td>

                                <td className="action-btns">
                                  <Link to="/service/update" state={row}>
                                    <Typography className="edit-btn">
                                      <i className="fas fa-edit" />
                                    </Typography>
                                  </Link>
                                  <Typography
                                    className="delete-btn"
                                    onClick={(e) => handlDeleteById(row._id)}
                                  >
                                    <i className="fas fa-trash-alt" />
                                  </Typography>
                                </td>
                               
                              </tr>
                            );
                          })
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            count={pageSize}
            page={currentPage}
            siblingCount={1}
            onChange={(e, value) => setCurrentPage(value)}
          />
        </div>
      </main>
    </div>
  );
};

export default AllServices;
