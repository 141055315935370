import React from "react";
import Footer from "../../components/Footer";
import "./AboutUs.css";

const AccountDeletion = () => {
  return (
    <div
      style={{
        marginTop: "170px",
      }}
    >
      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Account Deletion Steps for “Karibu”
      </h2>
      <div className="aboutus-content">
        <p style={{ width: "100%", margin: "0 auto", paddingBottom: "150px" }}>
          <li>1. Open the application.</li>
          <li>2. Go to account section from bottom tabs.</li>
          <li>3. Scroll down & click to remove/delete account.</li>
          <li>4. Click proceed & your account will be removed successfully.</li>
          <li>
            Note* All data regarding user details gets removed permanently, when
            user chooses to delete his/her account
          </li>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default AccountDeletion;
